import initAlienUtils from '@wasm/alien_utils'
import React from 'react'

import sdk from '@lib/sdk'
import Sentry from '@lib/sentry'
import wasmUtils from '@lib/wasm'
import App from '@sdk/Route/App'
import PopupContainerContext from '@ui/Tooltip/ContainerContext'

export interface SDKSearchParams {
  root: HTMLElement
  locale: Locale
  partnerNumber: RetailerNumber
  carrierCode: CarrierCode
  departureStation: StationCode
  arrivalStation: StationCode
  target?: SDK.Target
  currency?: Currency
}

export interface SDKSearchObject {
  unmount: () => void
}

const mount = ({ root, ...props }: SDKSearchParams): SDKSearchObject => {
  const mountPoint = sdk.mountInShadowDom(root)
  const params = {
    ...props,
    target: props.target ?? 'current',
    partnerNumber: Number(props.partnerNumber),
  }
  const popupContainer = sdk.mountPopupContainer()
  Sentry.init({ global: false })

  wasmUtils.withWasmSupport(initAlienUtils, () => {
    mountPoint.render(
      <PopupContainerContext.Provider value={popupContainer}>
        <App {...params} />
      </PopupContainerContext.Provider>,
    )
  })

  return {
    unmount: (): void => {
      mountPoint.unmount()
    },
  }
}

export default {
  mount,
}

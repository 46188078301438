const FARE_1 = 'FARE-1'
const FARE_2 = 'FARE-2'
const FARE_3 = 'FARE-3'
const FARE_4 = 'FARE-4'
const BUSINESS_CLASS = 'business'

const getFareClass = (tripType: Landing.TripType, tripClass?: Landing.TripClass): string => {
  let type = tripType as string

  if (tripClass === BUSINESS_CLASS) {
    type = `${tripType}_business`
  }

  const tripTypeFareClasses: Record<string, string> = {
    one_way: FARE_1,
    two_way: FARE_1,
    open_return: FARE_2,
    one_way_business: FARE_3,
    open_return_business: FARE_4,
  }

  return tripTypeFareClasses[type] || FARE_1
}

const getStandardFareClass = (isReturn: boolean): string => {
  return isReturn ? FARE_2 : FARE_1
}

export default {
  getFareClass,
  getStandardFareClass,
}
